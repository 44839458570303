<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Entry -->
        <b-col
          cols="6"
          md="9"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              :placeholder="$t('globalActions.find')"
            />
          </div>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="3"
          class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
        >
          <div class="col col-lg-auto">
              <b-button
                variant="success"
                @click="ExportExcel"
              > 
                <feather-icon icon="DownloadIcon" /> {{ $t('globalActions.excel') }}
              </b-button>
          </div>

        </b-col>
      </b-row>
    </div>

    <b-table
      id="refListTable"
      ref="refListTable"
      no-provider-paging
      no-provider-filtering
      :filter-ignored-fields="columnToNotBeFilter"
      :fields="tableColumns"
      :items="itemLists"
      :per-page="perPage"
      :current-page="currentPage"
      :filter="searchQuery"
      responsive
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      :busy="isBusy"
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >

      <template #table-busy>
        <div class="text-center text-primary my-2 d-flex flex-column justify-content-center">
          <div class="text mb-1">
            <b-spinner class="align-middle" />
          </div>
          <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
        </div>
      </template>

      <!-- Column: name -->
      <template #cell(picName)="data">
          <b-link
            :to="{ name: 'apps-master-data-user-detail', params: { id: data.item.id }}"
            class="font-weight-bold"
          >
          <span class="font-weight-bolder d-block text-nowrap">{{ data.item.user.user_name }}</span>
          <small class="font-italic text-secondary">({{ data.item.user.user_code }})</small>
          </b-link>
      </template>

      <!-- Column: email -->
      <template #cell(picEmail)="data">
          {{ data.item.user.user_email }}
      </template>
      
      <!-- Column: role -->
      <template #cell(role)="data">
        <span class="font-weight-bolder d-block text-nowrap">{{ !data.item.roles || !data.item.roles.name ? '' : data.item.roles.name }}</span>
      </template>

      <!-- Column: office -->
      <template #cell(office)="data">
        <span class="font-weight-bolder d-block text-nowrap">{{ !data.item.office || !data.item.office.name ? '' : data.item.office.name }}</span>
        <div v-if="data.item.office">
          <small class="font-italic text-secondary">({{ !data.item.office || !data.item.office.code ? '' : data.item.office.code }})</small>
        </div>
      </template>

      <!-- Column: National -->
      <template #cell(isNational)="data">
        <span class="font-weight-bolder d-block text-nowrap">{{ !data.item.is_national ? 'FALSE' : 'TRUE' }}</span>
      </template>

      <!-- Column: Active -->
      <template #cell(isActive)="data">
        <span class="font-weight-bolder d-block text-nowrap">{{ !data.item.is_active ? 'FALSE' : 'TRUE' }}</span>
      </template>

      <template #cell(user)="{ item }">
        <span class="font-weight-bolder d-block text-nowrap">
        {{ item.updated_process }}  By {{ item.user.user_name }}
        </span>
        <small class="font-italic">{{ new Date(item.user.updated_at).toDateString() }}</small>
      </template>

    </b-table>

    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalList"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            aria-controls="refInvoiceListTable"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>

  </b-card>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BLink,
  BPagination,
  BSpinner,
  BButton
} from 'bootstrap-vue'

// import useList from './useList'
import useListTable from '@/comp-functions/useListTable'
import { unformatNumber } from '@/utils/formatter'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BLink,
    BPagination,
    BSpinner,
    BButton
  },
  setup () {
    // Table Handlers
    const tableColumns = [
      { key: 'picName', label: 'Name' },
      { key: 'picEmail', label: 'Email' },
      { key: 'role', label: 'Role' },
      { key: 'office', label: 'Office' },
      { key: 'isNational', label: 'National' },
      { key: 'isActive', label: 'Active' },
      { key: 'user', label: 'Audit' }
    ]
    
    // Table Handlers
    const columnToNotBeFilter = [
      'isNational',
      'isActive'
    ]

    
    return {
      tableColumns,
      columnToNotBeFilter,
      ...useListTable({
        url: 'master/user'
      })
    }
  },
   methods: {
    ExportExcel () {
       import('@/vendors/Export2Excel').then(excel => {
        const list = this.itemLists
        for (let index = 0; index < list.length; index++) {
          list[index].picName = list[index].user.user_name
          list[index].picEmail = list[index].user.user_email
          list[index].role = list[index].roles.name
          list[index].office = list[index].office !== null ? list[index].office.name: '-'
          list[index].isNational = list[index].is_national ? 'FALSE' : 'TRUE'
          list[index].isActive = list[index].is_active ? 'TRUE' : ' FALSE'

        }

        const date = new Date();
        
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
      
        let currentDate = `${day}-${month}-${year}`;

        const headerTitle = this.tableColumns.map(column => column.label)

        const headerVal = this.tableColumns.map(column => column.key)

        const data = this.formatJson(headerVal, list).map((row) => {
          return row.map((cell) => {
            return unformatNumber(cell);
          })
        });
        excel.export_json_to_excel({
          multiHeader: [
            ['Master Data', '', '', '', '', '', ''],
            ['Jurnal Template', '', '', '', '', '', ''],
            [currentDate, '', '', '', '', '', ''],
            ['', '', '', '', '', '', '']
          ],
          header: headerTitle,
          data,
          merges: [
            { s: { c: 0, r: 0 }, e: { c: 6, r: 0 } },
            { s: { c: 0, r: 1 }, e: { c: 6, r: 1 } },
            { s: { c: 0, r: 2 }, e: { c: 6, r: 2 } },
            { s: { c: 0, r: 3 }, e: { c: 6, r: 3 } }
          ],
          filename: `Master data User`,
          autoWidth: true,
          bookType: 'xlsx'
        })

      })

    },
        formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        return v[j]
      }))
    }
  }
}
</script>
